.banner-wrapper {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;

    .image-wrapper {
        display: table;
        table-layout: fixed;
        width: 100%;

        .image {
            vertical-align: middle;
            background-image: linear-gradient(rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.7)), url(../../assets/118312753_306063110481851_4744030365979455696_n.jpg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;

            .banner {
                display: table;
                width: 100%;
                max-width: 800px;
                margin: auto;

                .inner-banner {
                    display: table-cell;
                    margin: auto;
                    vertical-align: middle;
                    color: #fffcf7;
                    text-shadow: 5px 2px 9px rgba(0,0,0,0.77);
                    font-family: 'Sigmar One', cursive;
                    font-size: 1.5em;
                    padding: 3em 2em;
                    text-transform: uppercase;
                }
            }
        }
    }
}