.intro-wrapper {
    margin: 1rem 0 1rem 0;

    .intro {
        text-transform: uppercase;
        font-size: 1.85em;
        color: #210124;
        font-family: 'Titan One', cursive;
        text-shadow: 4px 3px 9px rgba(33,1,36,0.62)
    }
}

div .brisket {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3em;
    background-image: linear-gradient(rgba(100, 100, 100, 0.3),
    rgba(100, 100, 100, 0.3)), url(../../assets/sloppybbq2.jpg);
    background-position: 50% 50%;
    background-size: cover;

    .logo {
    width: 100%;
    }
}