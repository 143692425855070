.navbar {
    background-color: #750d37;
    opacity: 0.8;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .navbar-burger span {
        background-color: #fffcf7;
    }

    .navbar-menu {
        background-color: #750d37;

        .navbar-item > a {
            color: #fffcf7;
        }

        .navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover {
            background-color: #750d37;
        }
    }
}

.about > h1.title, .menu > h1.title, .contact > h1.title, .contact > p.title, .gallery {
    font-size: 2em;
    font-family: 'Sigmar One', cursive;
    margin-bottom: 1rem;
    color: #210124;
    text-shadow: 3px 1px 9px rgba(33,1,36,0.62);
}

.box {
    margin: 1.25rem;

    .column {
        padding: 0;
    
        .message-header {
            background-color: #b3dec1;
            position: static;
        }
        
        .subtitle, .subtitle:not(:last-child), .title:not(:last-child) {
            padding-top: 2%;
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 15px;
        }
    
        .notification .subtitle {
            color: #210124;
        }
    
        .notification {
            background-color: transparent;
            padding: 0;
            padding: 0.5rem 0.1rem 0.5rem 0.5rem;
        }
    
        .notification:not(:last-child) {
            margin-bottom: 0;
        }
    
        .card-content {
            padding: 0;
            padding-bottom: 1%;
        }
    }
}


.intro-wrapper .intro {
    text-align: center;
    margin-bottom: 1.5rem;
}

.columns > .address, .columns > .hours {
    text-align: center;
}

.subtitle:not(:last-child), .title:not(:last-child) {
    margin-bottom: 0;
}

.about {
    margin-top: 1rem;
}

.menu {
    margin-top: 1rem;
}

.contact {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .inner-banner > p {
        font-size: 3rem;
    }

    .intro-wrapper .intro {
        text-align: center;
        font-size: 2.5em;
    }
}

@media screen and (min-width: 1020px) {
    .banner-wrapper .image-wrapper .image .banner .inner-banner {
        padding: 3em 3em;

        p {
            font-size: 4rem;
        }
    }

    .about > .row {
        margin-top: 3rem;


        .intro-wrapper {
            margin: 0;
            margin: 0 0 0 0.5rem;
        }
    
        .intro-wrapper .intro {
            text-align: left;
            font-size: 2.70em;
        }
    }

    .description > p {
        font-size: 1.2em;
    }

    .menu {
        margin-bottom: 2rem;
    }

    .address {
        text-align: end;
    }

    .column > .hours {
        text-align: left;
        margin-left: 7rem;
    }

    .phone, .social-media {
        text-align: left;
    }

    .phone {
        margin-left: 2rem;
    }
}